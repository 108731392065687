// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/contact/contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-experience-experience-js": () => import("./../../../src/pages/experience/experience.js" /* webpackChunkName: "component---src-pages-experience-experience-js" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-projects-js": () => import("./../../../src/pages/projects/projects.js" /* webpackChunkName: "component---src-pages-projects-projects-js" */),
  "component---src-pages-soon-js": () => import("./../../../src/pages/soon.js" /* webpackChunkName: "component---src-pages-soon-js" */)
}

